<template>
  <div class="error-page">
    <div class="e-container">
      <div class="e-hd">
        <img src="../../assets/images/404error.png" alt="" />
      </div>
      <div class="e-bd">
        <div class="b-title">{{mes}}</div>
        &nbsp; &nbsp;
        <div class="b-txt">抱歉，您访问的页面出错了</div>
      </div>
      <div class="back-home">
        <a href="/home"> 返回首页 </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"errorPage",
    data(){
      return{
        mes:'您可能输错了网址，或该网页已被删除、不存在等',
      }  
    },
    created() {
      if(this.$route.query.mes!=undefined){
        this.mes = this.$route.query.mes;
      }
    }
  }
</script>

<style lang="less" scoped>
.error-page {
  background: #f9f9f9;
  .e-container {
    width: 1200px;
    margin: 0 auto;
    min-height: 600px;
    // border: 1px solid #e1251b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .e-hd {
      width: 306px;
      height: 194px;
      //   border: 1px solid #e1251b;
      img {
        width: 306px;
        height: 194px;
      }
    }
    .e-bd {
      margin: 20px 0px;
      .b-title {
        font-size: 24px;
        color: #282828;
        // font-weight: bold;
      }
      .b-txt {
        text-align:center;
        font-size: 14px;
        color: #666;
      }
    }
    .back-home {
      width: 100px;
      height: 40px;
      border: 2px solid #efefef;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      border-radius: 5px;
      color: #999;
      &:hover {
        border: 2px solid #e1251b;
        a {
          color: #e1251b;
        }
      }
    }
  }
}
</style>